<template>
  <div class="tabs-material-subtitle">
    <Container>
      <h2>Calendrier des notations</h2>
    </Container>
  </div>

  <List :api="list.api" :header="list.headers" :items="list.col" :item-key="list.key" disableCheckbox disableActions>
    <template v-slot:date_previsionnelle="{ item }">
      <template v-if="item.date_previsionnelle">
        {{ helperService.displayDate(item?.date_previsionnelle) }}
      </template>
      <template v-else-if="item?.stade">
        {{ item?.stade?.code_bbch }} - {{
          item.stade.designation_court ?
            item.stade.designation_court :
            item.stade.designation
        }}
      </template>
      <template v-else>
        —
      </template>
    </template>
    <template v-slot:type="{ item }">
      {{ item.type.designation }}
    </template>
    <template v-slot:actions="{ item }">
      <Btn default icon="visibility" :to="{
        name: 'protocolExperimentalTaskGeneralInformation',
        params: {
          id: $route.params.id,
          tid: item.id
        },
      }" round grow />
    </template>
  </List>
</template>

<script>
import Container from '@/components/layout/Container.vue'
import List from '@/components/list/List.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'ProtocolSubItemRatingCalendar',

  components: {
    List,
    Container,
    Btn,
  },

  props: {
    pageTitle: {
      type: String,
    },
    protocol: {
      type: Object,
    },
  },

  data() {
    return {
      // List
      list: {
        api: {
          endpoint: `protocole/${this.$route.params.id}/evenement`,
          params: {
            sort: null,
            page: null,
            limit: 0,
            filters: null,
          },
        },
        headers: [
          { label: 'Date ou stade' },
          { label: 'Type d\'évenement' },
          { label: 'Désignation' },
          { label: 'Actions', align: 'right' },
        ],
        col: ['date_previsionnelle', 'type', 'designation', 'actions'],
        key: 'id',
      },
    }
  },

  mounted() {
    //
  },

  methods: {
    //
  },
}
</script>
